<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Storico rilevazioni</ion-title>
      </ion-toolbar>
    </ion-header> 
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>   
      <div id="container">
        <ion-item style="margin-top:60px;">
          <label>Attività: </label>
          <ion-select id="selcliente" placeholder="Seleziona numero attività" v-model="id_azione" >
            <ion-select-option :value="m.id" v-for="m,i in monitoraggi" :key="'monitoraggio-'+i">{{m.rif_cliente}}({{m.id}})</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-radio-group v-model="dato_sel" v-if="id_azione!=''"  @click="getSonde">
          <ion-list-header>
            <ion-label>Dato da visualizzare</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label>Temperatura</ion-label>
            <ion-radio slot="start" value="temp" @click="sonda_sel='', svuotaChart()"></ion-radio>
          </ion-item>
          <ion-item>
            <ion-label>Umidità</ion-label>
            <ion-radio slot="start" value="umid" @click="sonda_sel=''"></ion-radio>
          </ion-item>
        </ion-radio-group>

        <ion-item style="margin-top:60px;"  v-if="id_azione!='' && dato_sel!=''">
          <label>Sensore: </label>
          <ion-select id="selcliente" placeholder="Seleziona sensore" v-model="sonda_sel">
            <ion-select-option :value="s.SourceId" v-for="s,i in sonde" :key="'sonda-'+i">{{s.SourceId}}</ion-select-option>
          </ion-select> 
        </ion-item>         
        <br>
        <ion-button @click="creaChartTemp()" v-if="id_azione!='' && sonda_sel!='' && dato_sel=='temp'">Visualizza</ion-button>
        <ion-button @click="creaChartUmid()" v-if="id_azione!='' && sonda_sel!='' && dato_sel=='umid'">Visualizza</ion-button>
        <ion-button @click="esportaExcelTemp()" v-if="id_azione!='' && sonda_sel!='' && dato_sel=='temp'" style="--ion-color-primary:#2c912f">
          <ion-icon :icon="downloadOutline"></ion-icon> (sonda sel.)
        </ion-button>
        <ion-button @click="esportaExcelTempAll()" v-if="id_azione!='' && dato_sel=='temp'" style="--ion-color-primary:#2c912f">
          <ion-icon :icon="downloadOutline"></ion-icon> (totale)
        </ion-button>
        <ion-button @click="esportaExcelUmid()" v-if="id_azione!='' && sonda_sel!='' && dato_sel=='umid'" style="--ion-color-primary:#2c912f">
          <ion-icon :icon="downloadOutline"></ion-icon> (sonda sel.)
        </ion-button>
        <ion-button @click="esportaExcelUmidAll()" v-if="id_azione!='' && dato_sel=='umid'" style="--ion-color-primary:#2c912f">
          <ion-icon :icon="downloadOutline"></ion-icon> (totale)
        </ion-button>
        <br><br>         
        <canvas id="myChart" style="width:95%;"></canvas>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButton,
    IonButtons, 
    IonContent, 
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonListHeader,
    IonMenuButton, 
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption, 
    IonTitle, 
    IonToolbar,
    toastController 
  } from '@ionic/vue';
  import { Chart } from 'chart.js';
  import {downloadOutline} from 'ionicons/icons';


  export default {
    name: 'Folder',
    components: {
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonIcon,
      IonItem,
      IonLabel,
      IonListHeader,
      IonMenuButton,
      IonPage,
      IonRadio,
      IonRadioGroup,
      IonSelect,
      IonSelectOption,
      IonTitle,
      IonToolbar,
    },
    data : function(){
      return{
        strRisultati:[],
        formattedTime:[],
        formattedDay:[],
        date:[],
        temperature:[],
        sonde:[],
        id_azione:0,
        sonda_sel:'',
        dato_sel:'',
        temperatura:{
          deviceId:0,
          user:0,
          temperatura:0,
          temperaturaMax:0,
          temperaturaMin:0,
        },
        registrazione:{
          timestamp:'',
          rif_cliente:'',
        },
        monitoraggi:{
          id:'',
          timestampstart:'',
          timestampend:'',
          user:'',
          rif_cliente:'',
          stato:''
        },
        lastMonitoraggio:{
          stato:''
        },
        downloadOutline:downloadOutline,
      }
    },
    methods:{
      getUser(){
        this.axios.post(this.apiUrl + 'get-user', {}, this.headers)
        //.then(async (response) => {
        //  console.log(response)
        //})
      },
      getTemperature(){
        this.axios.post(this.apiUrl + 'get-temperature', {}, this.headers)
        .then(async (response) => {
          this.risultati=response.data.result
          this.strRisultati= JSON.stringify(this.risultati)
          this.strRisultati = JSON.parse(this.strRisultati)
        })
      },
      getSonde(){
        this.axios.post(this.apiUrl + 'get-sonda', {id_azione:this.id_azione, dato_sel:this.dato_sel}, this.headers)
        .then(async (response) => {
          if(this.dato_sel=='temp'){
            this.sonde=response.data.sonde
          }else{
            this.sonde=response.data.sondeumidita
          }
        })
      }, 
      async inizioRegistrazione() {
        this.axios.post(this.apiUrl + 'apri-azione', {registrazione:this.registrazione}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){ 
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            window.location.reload();
            return toast.present();
          }
        })
      },
      async fineRegistrazione() {
        this.registrazione= new Date();
        this.axios.post(this.apiUrl + 'chiudi-azione', {registrazione:this.registrazione}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){ 
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            window.location.reload();
            return toast.present();
          }
        })
      },
      creaChartTemp(){
        this.axios.post(this.apiUrl + 'get-temperature-grafico', {id_azione:this.id_azione, sonda_sel:this.sonda_sel}, this.headers)
        .then(async (response) => {
          this.date=response.data.date
          this.temperature=response.data.temperature

          var yValues= [];
          var yValuesTemp= [];
          var xValues = [];
          var xValuesTemp = [];

          for(var i=0; i<this.temperature.length; i++){
              yValuesTemp.push(this.temperature[i]);
          }

          for(var j=0;j<yValuesTemp.length;j++){
            if(yValuesTemp[j].temperatura==850){
              yValues.push('ERR');
            }
            yValues.push(yValuesTemp[j].temperatura);
          }

          for(var k=0; k<this.date.length; k++){
            xValuesTemp.push(this.date[k]);
          }

          for(var l=0;l<xValuesTemp.length;l++){
            xValues.push(xValuesTemp[l].date);
          }

          var maxVal=Math.max(...yValues);
          maxVal=maxVal+((maxVal*10)/100);

          new Chart("myChart", {
            type: "line",
            data: {
              labels: xValues,
              datasets: [{
                fill: false,
                lineTension: 0,
                backgroundColor: "rgba(42, 149, 232,1.0)",
                borderColor: "rgba(0,0,255,0.1)",
                data: yValues
              }]
            },
            options: {
              legend: {display: false},
              responsive: true,
              scales: {
                yAxes: [{ticks: {min: 0, max:maxVal}}],
                pointDot : true,
              }
            }
          });
        })
      },
      creaChartUmid(){
        this.axios.post(this.apiUrl + 'get-umidita-grafico', {id_azione:this.id_azione, sonda_sel:this.sonda_sel}, this.headers)
        .then(async (response) => {
          this.date=response.data.date
          this.humidity=response.data.humidity

          var yValues= [];
          var yValuesTemp= [];
          var xValues = [];
          var xValuesTemp = [];

          for(var i=0; i<this.humidity.length; i++){
              yValuesTemp.push(this.humidity[i]);
          }
          for(var j=0;j<yValuesTemp.length;j++){
              yValues.push(yValuesTemp[j].umidita);
          }
          for(var k=0; k<this.date.length; k++){
            xValuesTemp.push(this.date[k]);
          }
          for(var l=0;l<xValuesTemp.length;l++){
            xValues.push(xValuesTemp[l].date);
          }

          var maxVal=Math.max(...yValues);
          maxVal=maxVal+((maxVal*10)/100);

          new Chart("myChart", {
            type: "line",
            data: {
              labels: xValues,
              datasets: [{
                fill: false,
                lineTension: 0,
                backgroundColor: "rgba(42, 149, 232,1.0)",
                borderColor: "rgba(0,0,255,0.1)",
                data: yValues
              }]
            },
            options: {
              legend: {display: false},
              scales: {
                yAxes: [{ticks: {min: 0, max:maxVal}}],
                responsive: true,
                pointDot : true,
              }
            }
          });
        })
      },
      svuotaChart(){
        document.getElementById("myChart").classList.toggle('vuoto');
      },
      getMonitoraggi(){
        this.axios.post(this.apiUrl + 'get-monitoraggi', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.monitoraggi=response.data.monitoraggi           
          }
        })
      },
      getLastMonitoraggio(){
        this.axios.post(this.apiUrl + 'get-last-monitoraggio', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.lastMonitoraggio=response.data.monitoraggio              
          }
        })
      },
      esportaExcelTemp(){
        window.open('http://backend.hbj-group.com/download-excel-temp?token='+this.$store.state.apiToken+'&id_azione='+this.id_azione+'&sonda_sel='+this.sonda_sel,'_blank')
      },
      esportaExcelTempAll(){
        window.open('http://backend.hbj-group.com/download-excel-temp-all?token='+this.$store.state.apiToken+'&id_azione='+this.id_azione,'_blank')
      },
      esportaExcelUmid(){
        window.open('http://backend.hbj-group.com/download-excel-umid?token='+this.$store.state.apiToken+'&id_azione='+this.id_azione+'&sonda_sel='+this.sonda_sel,'_blank')
      },
      esportaExcelUmidAll(){
        window.open('http://backend.hbj-group.com/download-excel-umid-all?token='+this.$store.state.apiToken+'&id_azione='+this.id_azione,'_blank')
      },
    },
    ionViewWillEnter: function(){
      this.getUser();
      this.getTemperature();
      this.getMonitoraggi();
      this.getLastMonitoraggio();
      }
    }
</script>

<style scoped>
  #container {
    min-height:100%;
    display:block;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
  }

</style>